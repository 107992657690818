export const IMG_REGEX =
  /!\[([^\]])*]\(((https?:\/\/)[-A-Z0-9+&@()#/*%?=~_|!:,.;]*[-A-Z0-9+&@#()/%=~_|])\)/gi;

export const FOOTNOTE_REGEX =
  /\[(\[[^\]]*])]\(((https?:\/\/|mailto:)[-A-Z0-9+&@#()/%?=~_|!:,.;]*[-A-Z0-9+()&@#/%=~_|])\)/gi;
export const URL_REGEX =
  /\[([^\]]*)]\(((https?:\/\/|mailto:)[-A-Z0-9+&@#()/%?=~_|!:,.;–]*[-A-Z0-9+()&@#/%=~_|])\)/gi;
export const HYPERHIGHLIGHT_REGEX = /__(\S[\s\S]*?)__/g;
export const BOLD_REGEX = /\*\*(\S[\s\S]*?)\*\*/g;
export const BOLD_ITALICS_REGEX = /\*\*\*(\S[\s\S]*?)\*\*\*/g;
export const ITALICS_REGEX = /\*(\S[\s\S]*?)\*/g;

// Adapted from reactjs/textUtil.js i.e. Readwise 1.0 highlight markdown parsing.
export default function highlightMarkdownToHtml(markdown: string): string {
  return markdown
    .replace(HYPERHIGHLIGHT_REGEX, '<u>$1</u>')
    .replace(BOLD_ITALICS_REGEX, '<strong><em>$1</em></strong>')
    .replace(BOLD_REGEX, '<strong>$1</strong>')
    .replace(ITALICS_REGEX, '<em>$1</em>')
    .replace(IMG_REGEX, '<img src="$2" alt="$1" class="in-highlight-image"/>')
    .replace(URL_REGEX, '<a href="$2" target="_blank">$1</a>')
    .replace(FOOTNOTE_REGEX, '<sup><a href="$2" target="_blank">$1</a></sup>')
    .replace(/\n/g, '<br>');
}
